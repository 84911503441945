import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyDRD_GaDbhmVJ6w_CEcOndFRkcu7BDuECY',
  authDomain: 'mfp-auth-39d18.firebaseapp.com',
  projectId: 'mfp-auth-39d18',
  storageBucket: 'mfp-auth-39d18.appspot.com',
  messagingSenderId: '534170338008',
  appId: '1:534170338008:web:77b77a735f0d9dd1840dad',
};

const app = initializeApp(firebaseConfig);
